@import url("https://fonts.googleapis.com/css2?family=Heebo:wght@400;700&family=Nunito+Sans:wght@300;400;600;700&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    @apply antialiased;
  }
  h1, .heading-1 {
    @apply font-display text-2xl text-light font-normal lowercase leading-snug;
  }
  h2, .heading-2 {
    @apply font-display text-xl text-default font-light pb-5 leading-snug;
  }
  h3, .heading-3 {
    @apply font-display text-lg text-default font-semibold leading-snug;
  }
  h4, .heading-4 {
    @apply font-display text-base text-default font-bold lowercase leading-snug;
  }
  h5, .heading-5 {
      @apply font-display text-sm text-default font-semibold leading-snug;
  }
  p {
    @apply font-base text-sm text-default text-opacity-60 font-normal normal-case leading-loose mb-2;
  }
  .horizontal-grid p {
    @apply mb-0;
  }
  body {
    @apply bg-grey-lightest;
  }
  main, footer {
    @apply max-w-site mx-auto my-0;
  }
  hr {
    @apply border-grey-light my-8;
  }
}


@layer utilities {
  .lede {
    @apply font-base uppercase text-default text-opacity-80 font-normal text-xs;
  }
  .date, .small {
    @apply font-base text-xxs text-default text-opacity-60 font-normal;
  }
  .small-bold {
    @apply font-base text-xxs text-default text-opacity-60 font-bold;
  }
  .disclaimer {
    @apply font-base text-opacity-80 font-normal text-disclaimer;
  }
  .global-animation {
    @apply transition ease-in-out duration-300;
  }
  div.__react_component_tooltip {
    @apply py-3 px-4 whitespace-pre-wrap max-w-tooltip break-normal
  }
  div .horizontal-grid:last-of-type li div {
    @apply border-0
  }
  .placeholder {
    @apply text-default text-opacity-60 font-normal;
  }
  .placeholder::placeholder {
    @apply text-default text-opacity-60;
  }
  .form-error {
    @apply text-xs text-red mb-3 relative block;
  }
}

:root {
  --color-primary: #40a4bc;
  --color-primary-dark: #3a7e99;
  --color-secondary: #29b48b;
  --color-tertiary: #1c4968;
}

::-webkit-input-placeholder { /* WebKit browsers */
  text-transform: uppercase;
}
:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
  text-transform: uppercase;
}
::-moz-placeholder { /* Mozilla Firefox 19+ */
  text-transform: uppercase;
}
:-ms-input-placeholder { /* Internet Explorer 10+ */
  text-transform: uppercase;
}
::placeholder { /* Recent browsers */
  text-transform: uppercase;
}

.col-count-3 {
  column-count: 3;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}

.spinner {
  /* animation: rotate 2s linear infinite; */
  z-index: 2;
  /* position: absolute; */
  /* top: 50%;
  left: 50%;
  margin: -25px 0 0 -25px; */
  /* width: 20px;
  height: 20px; */
}
.spinner .path {
  stroke: currentColor;
  /* stroke: #40a4bc; */
  /* stroke-linecap: round; */
  /* animation: dash 1.5s ease-in-out infinite; */
}

.horizontal-grid:first-of-type .border-dotted {
  border-style: none;
}

/* .horizontal-grid:first-child:last-child {
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
} */

.horizontal-grid:last-child {
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
}

.overflow-y-scroll:not(.bg-grey-darkest)::-webkit-scrollbar, .ranges::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 10px;
}

.overflow-y-scroll:not(.bg-grey-darkest)::-webkit-scrollbar-thumb, .ranges::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: var(--color-primary);
  -webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);
  box-shadow: 0 0 1px rgba(255,255,255,.5);
}
.overflow-y-scroll:not(.bg-grey-darkest)::-webkit-scrollbar-track, .ranges::-webkit-scrollbar-track {
  box-shadow: inset 0 0 10px 10px rgba(223,229,226);
  border: solid 1px transparent;
  border-radius: 5px;
}

.overflow-x-scroll:not(.bg-grey-darkest)::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 5px;
}

.overflow-x-scroll:not(.bg-grey-darkest)::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: var(--color-primary);
  -webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);
  box-shadow: 0 0 1px rgba(255,255,255,.5);
}
.overflow-x-scroll:not(.bg-grey-darkest)::-webkit-scrollbar-track {
  box-shadow: inset 0 0 10px 10px rgba(223,229,226);
  border: solid 1px transparent;
  border-radius: 5px;
}

.clamp-text {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
div.__react_component_tooltip {
  max-width: 220px;
  white-space: normal;
}

.highcharts-credits {
  display: none;
}

.highcharts-tooltip-box {
  fill: rgba(34,28,34,0.9);
  stroke-opacity: 0;
  border-radius: 6px;
}
.highcharts-shadow {
  display: none;
}

#clicks-graph .highcharts-point {
  visibility: visible !important;
  stroke: #fff;
  stroke-width: 1;
}
.highcharts-point,
#activities-graph .highcharts-point,
#impressions-graph .highcharts-point {
  stroke: #fff;
  stroke-width: 1;
}

.highcharts-halo {
  display: none;
}

.highcharts-empty-series {
  stroke: rgba(40, 42, 57, 0.2);
}
/* .download-1:hover {
  fill: #29b48b;
} */
.highcharts-legend {
  transform: translate(10px, 0px) !important;
}
#calls_clicks_web_events .highcharts-legend-item {
  background-color: #f7f7f7;
  border-radius: 18px;
}

.path.highcharts-point {
  visibility: visible;
}

/* .change-opacity > * {
  opacity: 0.7 !important;
} */

.highcharts-series-0 rect {
  clip-path: inset(1px 5% -2px 5% round 5px);
  width: 10px;
}

#chart-container-quartiles .highcharts-series-0 rect, 
#chart-container-quartiles .highcharts-column-series rect {
  clip-path: inset(1px 5% -2px 5% round 5px);
  stroke-width: 1px;
  width: 9px;
}

.change-opacity {
  opacity: 0.7;
}

.min-h-screen {
  min-height: 100vh;
}

/* Date Range Picker */
/* overall */
div.daterangepicker {
  background: rgba(34, 28, 34, 0.9);
  padding: 20px;
  border: 0;
}
div.daterangepicker::before, div.daterangepicker::after {
  display: none;
}
div.daterangepicker .drp-calendar .calendar-table {
  background: transparent;
  border: 0;
}
div.daterangepicker.show-ranges.ltr .drp-calendar.left {
  border: 0;
}
div.daterangepicker .drp-calendar .calendar-table th, div.daterangepicker .drp-calendar .calendar-table td {
  color: #ffffff;
}
div.daterangepicker .drp-calendar .calendar-table tr th {
  font-family: 'Heebo', sans-serif;
  font-size: 0.813rem;
  font-weight: normal;
}
div.daterangepicker .drp-calendar .calendar-table tr:first-child th {
  font-family: 'Nunito Sans', sans-serif;
  font-size: 0.875rem;
  font-weight: 600;
}
div.daterangepicker .drp-calendar .calendar-table td {
  font-family: 'Heebo', sans-serif;
  font-size: 0.813rem;
  padding: 5px;
}
div.daterangepicker td.off, div.daterangepicker td.off.in-range, div.daterangepicker td.off.start-date, div.daterangepicker td.off.end-date {
  color: #ffffff;
  background: transparent;
}
div.daterangepicker td.off, div.daterangepicker td.off.in-range {
  color: transparent !important;
  pointer-events: none;
}
div.daterangepicker td.in-range {
  background: var(--color-tertiary);
}
div.daterangepicker td.active {
  background-color: var(--color-primary);
}
div.daterangepicker .calendar-table th.available span {
  border-color: var(--color-primary);
}
/* div.daterangepicker .calendar-table th span:hover {
  color: #3a7e99;
} */
div.daterangepicker th.available:hover {
  background-color: transparent;
}

div.daterangepicker td.available:hover {
  background-color: var(--color-primary);
}

div.daterangepicker.show-calendar div.drp-buttons {
  border: 0;
  text-align: left;
  border-top: 1px solid rgba(255, 255, 255, 0.20);
}

div.daterangepicker div.drp-buttons .drp-selected {
  background: transparent;
  padding: 10px;
  border-radius: 6px;
  font-family: 'Heebo', sans-serif;
  font-size: 13px;
  color: #fff;
  margin-bottom: 20px;
  padding-right: 3rem;
  font-weight: bold;
}

div.daterangepicker .drp-buttons button.btn {
  padding: 10px;
  border-radius: 6px;
  border: solid 1px var(--color-primary);
  color: #fff;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 13px;
  text-transform: uppercase;
  font-weight: normal;
  max-width: 90px;
  width: 100%;
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;
  transition-duration: 300ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  margin-left: 0;
  margin-right: 20px;
}
div.daterangepicker .drp-buttons button.btn.btn-primary {
  background: var(--color-primary);
  margin-right: 0;
}
div.daterangepicker .drp-buttons button.btn:hover {
  background: var(--color-secondary);
}

div.daterangepicker .ranges ul li {
  color: #fff;
  font-family: 'Heebo', sans-serif;
  font-size: 13px;
}
div.daterangepicker .ranges {
  max-height: 250px;
  overflow-y: scroll;
}
div.daterangepicker .ranges ul li:hover {
  font-weight: bold;
  background: transparent;
}
div.daterangepicker .ranges ul li.active {
  font-weight: bold;
  background: transparent;
  color: var(--color-primary);
}

form ul.roi-form li:last-child hr {
  display: none;
}

form ul.pcampaign-form li:first-child hr {
  display: none;
}

@media screen and (min-width: 564px) {
  div.daterangepicker div.drp-buttons .drp-selected {
    position: relative;
    margin-bottom: 0;
  }
  div.daterangepicker.show-calendar div.drp-buttons {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    padding-top: 1.5rem;
  }
}

#admin-tools-menu div:last-child div:last-child ul:last-child {
  border-bottom: 0;
}



@media screen and (min-width: 768px) {
  .bg-login::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    background-image: url("assets/backgrounds/login-background.jpg");
    background-size: cover;
    height: 100%;
    width: 100%;
  }
}

main.login-page {
  max-width: none;
}

.highcharts-axis.highcharts-yaxis {
  font-weight: bold;
}

.mobile-header {
  position: fixed;
  top: -200px;
  left: 0;
  transition: top 0.2s ease-in-out;
  width: 100%;
  z-index: 99999;
}

.mobile-header > div {
  max-width: 960px;
  margin: 0 auto;
}
/* .login-form::before {
  content: '';
  background-color: white;
  left: 0;
  height: 100%;
  width: 100%;
} */
